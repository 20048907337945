// @flow

import React from 'react';
import { connect } from 'react-redux';

import moment from './moment-wrapper';
import CurrentStepBox from './CurrentStepBox';
import type { Theme } from './type';

type Props = {
  timeStamp: number,
  theme: Theme,
};

const DashboardHeader = ({ timeStamp, theme }: Props) => (
  <div
    style={{
      fontFamily: theme.font,
      color: theme.fontColor,
      borderColor: theme.primary,
      backgroundColor: theme.secondary,
    }}
    className="bb bw2 b--o-peach-primary flex-l justify-between-l pl5-l">
    <theme.logo className="h3 w-100 w4-l" />
    <div className="self-center-l f3 tc ttu pr2-l">
      {moment(timeStamp).format('LL')}
      <span className="ml3 fw5">{moment(timeStamp).format('LT')}</span>
    </div>
    <CurrentStepBox theme={theme} />
  </div>
);

const mapStateToProps = ({ tvdashboardState }, ownProps): Props => ({
  timeStamp: tvdashboardState.timeStamp,
  theme: ownProps.theme,
});

export default connect(mapStateToProps)(DashboardHeader);
