import moment from './moment-wrapper';

import {
  MONITORING_DATA_RECEIVED,
  CHANGE_TO_NEXT_VIEW,
  RESOURCE_NOT_FOUND,
  INITIAL_RENDER,
} from './actionTypes';

import {
  ENERGY_DAY,
  ENERGY_WEEK,
  ENERGY_MONTH,
  ENERGY_YEAR,
  PERIOD_TYPE_TODAY,
  PERIOD_TYPE_YESTERDAY,
  PERIOD_TYPE_PREVIOUS_WEEK,
  PERIOD_TYPE_THIS_WEEK,
} from './constants';

const initialState = {
  fetchFailed: false,
  monitoringSteps: [],

  power: [new Date().toISOString(), 0, 'W'],

  DAY: {
    fetched: false,
    values: [],
  },
  WEEK: {
    fetched: false,
    values: [],
  },
  MONTH: {
    fetched: false,
    values: [],
  },
  YEAR: {
    fetched: false,
    values: [],
  },

  currentEnergyDisplay: {
    type: 'energy',
    periodType: PERIOD_TYPE_TODAY,
    shortName: null,
    unit: 'W',
    data: 0,
    timeStamp: '',
  },

  dataReceived: false,
  maxPower: 1,
  extra: {},
  addressLines: [],
  locationData: {},
  currentStep: 0,
  timeStamp: 0,
};

// Function assumption: state.monitoringsteps only contain steps which have been fetched
const buildData = state => {
  if (state.monitoringSteps.length) {
    const currentStep =
      state.currentStep + 1 < state.monitoringSteps.length
        ? state.monitoringSteps[state.currentStep + 1]
        : state.monitoringSteps[0];
    // Find the last valid data entry in either HOUR, DAY, WEEK, MONTH or YEAR
    // const stepData = state[currentStep][state[currentStep].length - 1] || ['', 0];
    const interval = state[currentStep];
    // Assumes last data entry is placed at the last position,
    if (interval.values.length) {
      const lastDataEntry = interval.values[interval.values.length - 1];
      const extra = {
        timeStamp: lastDataEntry[0],
        data: lastDataEntry[1],
      };
      switch (currentStep) {
        case ENERGY_DAY:
          return {
            ...state.currentEnergyDisplay,
            shortName: null,
            periodType: moment(extra.timeStamp).isBefore(
              moment().startOf('day'),
            )
              ? PERIOD_TYPE_YESTERDAY
              : PERIOD_TYPE_TODAY,
            ...extra,
          };
        case ENERGY_WEEK:
          return {
            ...state.currentEnergyDisplay,
            shortName: null,
            periodType: moment(extra.timeStamp).isBefore(new Date(), 'week')
              ? PERIOD_TYPE_PREVIOUS_WEEK
              : PERIOD_TYPE_THIS_WEEK,
            ...extra,
          };
        case ENERGY_MONTH:
          return {
            ...state.currentEnergyDisplay,
            periodType: null,
            shortName: moment(lastDataEntry[0]).format('MMMM'),
            ...extra,
          };
        case ENERGY_YEAR:
          return {
            ...state.currentEnergyDisplay,
            periodType: null,
            shortName: new Date(lastDataEntry[0]).getFullYear(),
            ...extra,
          };
        default:
          throw new Error('Failed building data');
      }
    }
  }
  return null;
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MONITORING_DATA_RECEIVED: {
      const data = action.data.asset;

      const powerValues = data.power[0].data.filter(x => x.avg !== null);
      let { power } = state;
      if (powerValues.length) {
        const lastEntry = powerValues[powerValues.length - 1];
        power = [new Date(lastEntry.timestamp), lastEntry.value, 'W'];
      }

      let { maxPower } = state;
      if (
        data.maxPower &&
        data.maxPower[0].data.length &&
        data.maxPower[0].data.length
      ) {
        maxPower = data.maxPower[0].data[0].value;
      }

      return Object.assign({}, state, {
        fetchFailed: false,
        power,
        maxPower,
        DAY: {
          fetched: true,
          values: data.energy[0].daily.map(({ timestamp, sum }) => [
            new Date(timestamp),
            sum,
            'Wh',
          ]),
        },
        WEEK: {
          fetched: true,
          values: data.energy[0].weekly.map(({ timestamp, sum }) => [
            new Date(timestamp),
            sum,
            'Wh',
          ]),
        },
        MONTH: {
          fetched: true,
          values: data.energy[0].monthly.map(({ timestamp, sum }) => [
            new Date(timestamp),
            sum,
            'Wh',
          ]),
        },
        YEAR: {
          fetched: true,
          values: data.energy[0].yearly.map(({ timestamp, sum }) => [
            new Date(timestamp),
            sum,
            'Wh',
          ]),
        },
        monitoringSteps: ['DAY', 'WEEK', 'MONTH', 'YEAR'],
        dataReceived: true,
      });
    }

    case RESOURCE_NOT_FOUND:
      return Object.assign({}, state, {
        fetchFailed: true,
      });

    case INITIAL_RENDER:
      return Object.assign({}, state, {
        timeStamp: new Date().getTime(),
      });
    case CHANGE_TO_NEXT_VIEW: {
      const newEnergyData = buildData(state);
      return Object.assign({}, state, {
        currentStep:
          state.currentStep + 1 < state.monitoringSteps.length
            ? state.currentStep + 1
            : 0,
        currentEnergyDisplay: newEnergyData,
        timeStamp: new Date().getTime(),
      });
    }

    default:
      return state;
  }
}
