import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'Dashboard';

export default defineMessages({
  produced: {
    id: `${I18N_NAMESPACE}.produced`,
    defaultMessage: 'Electricity produced {timePeriod}',
  },
  producedInPeriod: {
    id: `${I18N_NAMESPACE}.producedInPeriod`,
    defaultMessage: 'in',
  },
  periodTypeToday: {
    id: `${I18N_NAMESPACE}.periodTypeToday`,
    defaultMessage: 'today',
  },
  periodTypeYesterday: {
    id: `${I18N_NAMESPACE}.periodTypeYesterday`,
    defaultMessage: 'yesterday',
  },
  periodTypePreviousWeek: {
    id: `${I18N_NAMESPACE}.periodTypePreviousWeek`,
    defaultMessage: 'previous week',
  },
  periodTypeThisWeek: {
    id: `${I18N_NAMESPACE}.periodTypeThisWeek`,
    defaultMessage: 'this week',
  },
  currentPower: {
    id: `${I18N_NAMESPACE}.currentPower`,
    defaultMessage: 'Current power:',
  },
  noCurrentPower: {
    id: `${I18N_NAMESPACE}.noCurrentPower`,
    defaultMessage: 'No current power',
  },
  peakPowerYesterday: {
    id: `${I18N_NAMESPACE}.peakPowerYesterday`,
    defaultMessage: 'Peak power yesterday:',
  },
  peakPowerToday: {
    id: `${I18N_NAMESPACE}.peakPowerToday`,
    defaultMessage: 'Peak power today:',
  },
  powerRatio: {
    id: `${I18N_NAMESPACE}.powerRatio`,
    defaultMessage: '{percentageRatio} % of peak capacity',
  },
  previousMeasurementReceivedAt: {
    id: `${I18N_NAMESPACE}.previousMeasurementReceivedAt`,
    defaultMessage: 'Previous measurement received {receivedAt}',
  },
  fetchErrorMessage: {
    id: `${I18N_NAMESPACE}.fetchErrorMessage`,
    defaultMessage:
      "We're having some problems fetching data about the solar production right now. Please come back in a couple of minutes.",
  },
  phoneHeader: {
    id: `${I18N_NAMESPACE}.phoneHeader`,
    defaultMessage: 'Equals',
  },
  phoneLabel: {
    id: `${I18N_NAMESPACE}.phoneLabel`,
    defaultMessage: 'full charges of an iPhone 7',
  },
  washingMachineRoundsHeader: {
    id: `${I18N_NAMESPACE}.washingMachineRoundsHeader`,
    defaultMessage: 'Corresponds to',
  },
  washingMachineRoundsLabel: {
    id: `${I18N_NAMESPACE}.washingMachineRoundsLabel`,
    defaultMessage: 'rounds of washing laundry',
  },
  teslaDistanceHeader: {
    id: `${I18N_NAMESPACE}.teslaDistanceHeader`,
    defaultMessage: 'A Tesla can drive',
  },
  teslaDistanceLabel: {
    id: `${I18N_NAMESPACE}.teslaDistanceLabel`,
    defaultMessage: 'kilometers with this production',
  },
  suppliedBy: {
    id: `${I18N_NAMESPACE}.suppliedBy`,
    defaultMessage: 'Supplied by',
  },
});
