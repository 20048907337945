/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage as T, injectIntl } from 'react-intl';

import {
  PERIOD_TYPE_TODAY,
  PERIOD_TYPE_YESTERDAY,
  PERIOD_TYPE_PREVIOUS_WEEK,
  PERIOD_TYPE_THIS_WEEK,
} from './constants';

import m from './messages';

class ProducedBlock extends PureComponent {
  getTimePeriod() {
    const { currentEnergyDisplay, intl } = this.props;
    if (currentEnergyDisplay.shortName) {
      return [
        intl.formatMessage(m.producedInPeriod),
        currentEnergyDisplay.shortName,
      ].join(' ');
    }

    const message = {
      [PERIOD_TYPE_TODAY]: m.periodTypeToday,
      [PERIOD_TYPE_YESTERDAY]: m.periodTypeYesterday,
      [PERIOD_TYPE_PREVIOUS_WEEK]: m.periodTypePreviousWeek,
      [PERIOD_TYPE_THIS_WEEK]: m.periodTypeThisWeek,
    }[currentEnergyDisplay.periodType];

    if (message) {
      return intl.formatMessage(message);
    }
    return null;
  }

  render() {
    return (
      <p className="mb0">
        <T {...m.produced} values={{ timePeriod: this.getTimePeriod() }} />
      </p>
    );
  }
}

ProducedBlock.propTypes = {
  currentEnergyDisplay: PropTypes.any.isRequired,
  intl: PropTypes.any.isRequired,
};

export default injectIntl(ProducedBlock);
