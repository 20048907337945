import React from 'react';
import { render } from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';

import reducer from './reducer';
import 'tachyons/css/tachyons.min.css';
import './styles/dashboard.css';
import './i18n';
import TvDashboard from './TvDashboard';

const devComposer =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name: 'Otovo',
      instanceId: '0',
    })) ||
  compose;

const composer = window.dashboard.includeReduxDevtools ? devComposer : compose;

const enhancer = composer(applyMiddleware(thunk));

const store = createStore(
  combineReducers({ tvdashboardState: reducer }),
  enhancer,
);

render(
  <Provider store={store}>
    <IntlProvider
      locale={window.dashboard.locale}
      messages={window.dashboard.messages}>
      <TvDashboard />
    </IntlProvider>
  </Provider>,
  document.getElementById('app'),
);
