// @flow

export const formatValue = (
  value: number | string,
  digits: number = 1,
): string => {
  if (typeof value === 'string') {
    return Number(Number(value).toFixed(digits)).toLocaleString('nb-NO');
  }
  if (typeof value === 'number') {
    return Number(value.toFixed(digits)).toLocaleString('nb-NO');
  }
  return '-';
};

export const unitFormat = (
  value: number,
  unitType: ?string = 'W',
  digits: ?number = null,
) => {
  const k = 1000;

  let formattedValue = value;
  let unit = typeof unitType === 'string' ? unitType : 'W';

  if (value >= k) {
    formattedValue = (value / k).toLocaleString('nb-NO', {
      minimumFractionDigits: digits !== null ? digits : 1,
      maximumFractionDigits: digits !== null ? digits : 1,
    });
    if (unitType != null) {
      unit = `k${unitType}`;
    }
  } else {
    formattedValue = value.toLocaleString('nb-NO', {
      minimumFractionDigits: digits !== null ? digits : 0,
      maximumFractionDigits: digits !== null ? digits : 0,
    });
  }
  return {
    value: formattedValue,
    unit,
  };
};
