import { request, gql } from 'graphql-request';

import {
  CHANGE_TO_NEXT_VIEW,
  MONITORING_DATA_RECEIVED,
  INITIAL_RENDER,
} from './actionTypes';

const nextView = () => ({
  type: CHANGE_TO_NEXT_VIEW,
});

function updateMonitoringDataV2(installationId) {
  return dispatch => {
    const weeklyStart = new Date(
      new Date() - 60 * 60 * 24 * 7 * 1000,
    ).toISOString();
    const dailyStart = new Date(
      new Date() - 60 * 60 * 24 * 1 * 1000,
    ).toISOString();
    const monthlyStart = new Date(
      new Date() - 60 * 60 * 24 * 30 * 1000,
    ).toISOString();
    const yearlyStart = new Date(
      new Date() - 60 * 60 * 24 * 365 * 1000,
    ).toISOString();

    const dataQuery = gql`
      {
        asset(id: "${installationId}") {
          power: timeSeries(kind: POWER) {
            data: values(
              start: "${dailyStart}"
              orderBy: TIMESTAMP
              orderDirection: ASC
              limit: 1440
            ) {
              timestamp
              value
            }
          }
          maxPower: timeSeries(kind: POWER) {
            data: values(start: "${yearlyStart}", orderBy: VALUE, orderDirection: DESC, limit: 1) {
              value
            }
          }
          energy: timeSeries(kind: ENERGY) {
            daily: aggregates(
              aggregates: SUM
              granularity: P1D
              start: "${dailyStart}"
              orderBy: TIMESTAMP
              orderDirection: ASC
            ) {
              timestamp
              sum
            }
            weekly: aggregates(
              aggregates: SUM
              granularity: P1W
              start: "${weeklyStart}"
              orderBy: TIMESTAMP
              orderDirection: ASC
            ) {
              timestamp
              sum
            }
            monthly: aggregates(
              aggregates: SUM
              granularity: P1M
              start: "${monthlyStart}"
              orderBy: TIMESTAMP
              orderDirection: ASC
            ) {
              timestamp
              sum
            }
            yearly: aggregates(
              aggregates: SUM
              granularity: P1Y
              start: "${yearlyStart}"
              orderBy: TIMESTAMP
              orderDirection: ASC
            ) {
              timestamp
              sum
            }
          }
        }
      }
    `;

    return request(`${window.dashboard.apiUrl}/graphql/`, dataQuery).then(
      data => {
        return dispatch({
          type: MONITORING_DATA_RECEIVED,
          data,
        });
      },
    );
  };
}

const initialMount = () => ({
  type: INITIAL_RENDER,
});

export { nextView, updateMonitoringDataV2, initialMount };
