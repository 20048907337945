// @flow

import React from 'react';
import { connect } from 'react-redux';

type Props = {
  className?: string,
  topText: string,
  bottomText: string,
  largeNumber: string,
};

const DashboardStats = ({
  className = '',
  topText,
  bottomText,
  largeNumber,
}: Props = {}) => (
  <div className={className}>
    <span className="db f3">{topText}</span>
    <span className="db mv3 f-headline">{largeNumber}</span>
    <span className="db f3">{bottomText}</span>
  </div>
);

DashboardStats.defaultProps = {
  className: '',
};

const mapStateToProps = ({ tvdashboardState }) => ({
  dataReceived: tvdashboardState.dataReceived,
});

export default connect(mapStateToProps)(DashboardStats);
