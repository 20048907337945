export const ENERGY_DAY = 'DAY';
export const ENERGY_WEEK = 'WEEK';
export const ENERGY_MONTH = 'MONTH';
export const ENERGY_YEAR = 'YEAR';

export const STEP_INTERVAL = 25 * 1000; // seconds
export const POLL_INTERVAL = 60 * 1000; // 1 minute poll interval
export const TIMEOUT_LIMIT = 80 * 1000; // we time out after 80 seconds, showing an error message

export const IPHONE_7_BATTERY_CAPACITY = 7.45; // Wh
export const WASHING_MACHINE_USAGE = 1.02 * 1000; // kwh/wash Based on Electrolux EWW1476HDW
export const TESLA_EPA_RANGE_FACTOR = 426 / (85 * 1000); // 426 km / 85 kWh battery

export const COLOR_PEACH = '#FF9B75';
export const COLOR_BLUE = '#3250B4';
export const COLOR_WHITE = '#FFF';

export const PERIOD_TYPE_TODAY = 'today';
export const PERIOD_TYPE_YESTERDAY = 'yesterday';
export const PERIOD_TYPE_PREVIOUS_WEEK = 'prev_week';
export const PERIOD_TYPE_THIS_WEEK = 'this_week';
