import React from 'react';
import PropTypes from 'prop-types';

import Active from '../assets/icons/active.svg';
import Idle from '../assets/icons/idle.svg';

const iconClasses = 'h-50 ma0';
const StepComponent = ({ isActive, color, background, ...rest }) => {
  const classes = `${iconClasses} text-${color}`;
  if (isActive) {
    return <Active className={classes} fill={background} {...rest} />;
  }

  return <Idle className={classes} fill={background} {...rest} />;
};

StepComponent.defaultProps = {
  isActive: false,
  color: 'o-gray-disabled',
  background: '#fff',
};

StepComponent.propTypes = {
  isActive: PropTypes.bool,
  background: PropTypes.string,
  color: PropTypes.string,
};

export { StepComponent as default, iconClasses };
