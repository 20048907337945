import OtovoLogo from '../assets/images/otovo.svg';
import CircleKlogo from '../assets/images/circle_k_logo.svg';

import { COLOR_PEACH, COLOR_BLUE, COLOR_WHITE } from './constants';

const theming = {
  default: {
    primary: COLOR_PEACH,
    secondary: COLOR_WHITE,
    alternative: COLOR_BLUE,
    fontColor: COLOR_BLUE,
    logo: OtovoLogo,
    font: 'Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif',
    secondaryLogo: null,
  },
  circlek: {
    primary: 'rgb(218, 41, 28)',
    secondary: COLOR_WHITE,
    alternative: 'rgb(237, 139, 0)',
    fontColor: 'rgba(0, 0, 0, .7)',
    logo: CircleKlogo,
    font: 'Arial, sans-serif',
    secondaryLogo: OtovoLogo,
  },
};

const getTheme = theme =>
  theme && theming[theme] ? theming[theme] : theming.default;

export { getTheme };
