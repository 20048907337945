/* eslint no-param-reassign: ["error", { "props": false }] */

const rand = (min, max) => Math.floor(Math.random() * (max - min + 1) + min); // eslint-disable-line
const ease = (t, b, c, d) => {
  if ((t /= d / 2) < 1) return (c / 2) * t * t + b; // eslint-disable-line
  return (-c / 2) * (--t * (t - 2) - 1) + b; // eslint-disable-line
};

// function Point(ctx, config, options) {
function Point({ x, y, duration, range, index }) {
  this.name = `point-${index}`;
  this.anchorX = x;
  this.anchorY = y;
  this.x = x;
  this.y = y;
  this.range = range;
  this.duration = duration;
  this.setTarget();
}

Point.prototype.setAnchor = function setPointAnchor(newAnchor) {
  // eslint-disable-line
  this.anchorY = newAnchor;
};

Point.prototype.setTarget = function setPointTarget() {
  this.initialX = this.x;
  this.initialY = this.y;
  this.targetX = this.anchorX + rand(0, this.range.x);
  this.targetY = this.anchorY + rand(0, this.range.y);
  this.randDuration = rand(this.duration.min, this.duration.max);
  this.tick = 0;
};

Point.prototype.update = function updatePoint() {
  const dx = this.targetX - this.x;
  const dy = this.targetY - this.y;
  const dist = Math.sqrt(dx * dx + dy * dy);
  if (Math.abs(dist) <= 1) {
    this.setTarget();
  } else {
    const { tick } = this; // current progress (point is updated on requestAnimationFrame in
    // BackgroundGraph, so time between ticks may vary)
    let initial = this.y; // starting position
    let diff = this.targetY - this.y; // the distance we're traveling
    const { randDuration } = this; // a random duration measured in ticks

    this.y = ease(tick, initial, diff, randDuration);

    initial = this.x;
    diff = this.targetX - this.x;
    this.x = ease(tick, initial, diff, randDuration);

    this.tick += 1;
  }
};

Point.prototype.render = function renderPoint(ctx) {
  ctx.beginPath();
  ctx.arc(this.x, this.y, 3, 0, Math.PI * 2, false);
  ctx.fillText(this.name, this.x, this.y);
  ctx.fillStyle = '#000';
  ctx.fill();
};

export { Point as default };
