/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import { FormattedMessage as T } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import m from './messages';
import { unitFormat, formatValue } from './formatValue';
import ProducedBlock from './ProducedBlock';

class MainSection extends Component {
  shouldComponentUpdate(nextProps) {
    const { currentEnergyDisplay, maxPower, power } = this.props;
    if (
      nextProps.power[1] !== power[1] ||
      nextProps.currentEnergyDisplay.data !== currentEnergyDisplay.data ||
      nextProps.maxPower !== maxPower ||
      nextProps.currentEnergyDisplay.shortName !==
        currentEnergyDisplay.shortName
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { className, currentEnergyDisplay, power, theme } = this.props;
    const { value, unit } = unitFormat(currentEnergyDisplay.data, 'Wh');

    let effectMessage = m.currentPower;

    // If there is no effect
    if (power[1] < 1) {
      effectMessage = m.noCurrentPower;
    }

    return (
      <div className={className} style={{ flexGrow: 3 }}>
        <div
          className="ttu tc dtc v-mid tv-large-number"
          style={{
            color: theme.fontColor,
            fontFamily: theme.font,
          }}>
          <ProducedBlock currentEnergyDisplay={currentEnergyDisplay} />
          <h1 className="mb0 lh-solid mv3">
            {value}
            <span className="dib v-top ttn mt4 absolute">{unit}</span>
          </h1>
          {power[1] >= 0 && (
            <p className="mb0">
              <T {...effectMessage} />
              {power[1] > 1 && (
                <span className="ttn">{` ${formatValue(power[1])} ${
                  power[2]
                }`}</span>
              )}
            </p>
          )}
        </div>
      </div>
    );
  }
}

MainSection.defaultProps = {
  className: '',
};

MainSection.propTypes = {
  className: PropTypes.string,
  currentEnergyDisplay: PropTypes.object.isRequired,
  power: PropTypes.array.isRequired,
  maxPower: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
  extra: PropTypes.object.isRequired,
};

const mapStateToProps = ({ tvdashboardState }) => ({
  currentEnergyDisplay: tvdashboardState.currentEnergyDisplay,
  dataReceived: tvdashboardState.dataReceived,
  power: tvdashboardState.power,
  maxPower: tvdashboardState.maxPower,
  extra: tvdashboardState.extra,
});

export default connect(mapStateToProps)(MainSection);
