import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { STEP_INTERVAL } from './constants';

class TimerBar extends Component {
  state = {
    progress: 0,
  };

  componentDidMount() {
    this.startTicker();
  }

  componentWillUnmount() {
    this.stopTicker();
  }

  startTicker = () => {
    this.tickerId = setTimeout(() => {
      this.setState({
        progress: ((new Date() - this.props.timeStamp) / STEP_INTERVAL) * 100,
      });
      window.requestAnimationFrame(this.startTicker);
    }, 1000 / 10);
  };

  stopTicker = () => {
    window.clearInterval(this.tickerId);
  };

  render() {
    if (this.props.timeStamp) {
      return (
        <div
          className="absolute bottom-0 left-0"
          style={{
            height: '0.35rem',
            width: `${this.state.progress}%`,
            backgroundColor: this.props.color,
          }}
        />
      );
    }
    return null;
  }
}

TimerBar.propTypes = {
  timeStamp: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

const mapStateToProps = ({ tvdashboardState }) => ({
  timeStamp: tvdashboardState.timeStamp,
});

export default connect(mapStateToProps)(TimerBar);
