import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Point from './Point';

class BackgroundGraph extends Component {
  constructor() {
    super();

    this._animationID = null;
    this._points = [];

    this.state = {
      canvasWidth: 0,
      canvasHeight: 0,
      updated: false,
      initialRender: true,
    };
  }

  componentDidMount() {
    if (this._graphContainer) {
      this.initGraph();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { percent } = this.props;
    if (nextProps.percent !== percent) {
      this.setState({
        updated: true,
      });
    }
  }

  // The component should not update after initial render
  // as the canvas is updating itself
  shouldComponentUpdate() {
    return this.state.initialRender;
  }

  clearGraph = () => {
    if (this._graphContainer) {
      this._graphContainer
        .getContext('2d')
        .clearRect(0, 0, this.state.canvasWidth, this.state.canvasHeight);
    }
  };

  initGraph = () => {
    const ctx = this._graphContainer.getContext('2d');
    ctx.lineJoin = 'round';

    const canvasWidth = this._graphContainer.getClientRects()[0].width;
    const canvasHeight = this._graphContainer.getClientRects()[0].height;
    this._points = Array.from({ length: this.props.count + 2 }, () => '');

    this.setState(
      {
        initialRender: false,
        canvasWidth,
        canvasHeight,
      },
      () => {
        this.start();
      },
    );
  };

  // "Game loop"
  loop = () => {
    setTimeout(() => {
      this.clearGraph();

      // If props are updated, we should set a new anchor at the new percentage
      if (this.state.updated) {
        this._points.forEach(point => {
          point.setAnchor(
            this.state.canvasHeight * ((100 - this.props.percent) / 100),
          );
          point.update();
        });

        this.setState({
          updated: false,
        });
      } else {
        this._points.forEach(point => {
          point.update();
        });
      }

      this.renderShape();

      if (this.props.shouldAnimate) {
        window.requestAnimationFrame(this.loop);
      }
    }, 1000 / 10);
  };

  start = () => {
    if (!this._animationID) {
      const { duration, count, range, level } = this.props;

      const spacing = (this.state.canvasWidth + range.x * 2) / count;
      this._points = this._points.map(
        (el, index) =>
          new Point({
            index,
            x: spacing * index - range.x,
            y: this.state.canvasHeight - this.state.canvasHeight * level,
            duration,
            range,
          }),
      );
      this._animationID = window.requestAnimationFrame(this.loop);

      // Force initial update of the graph after the world settles down.
      setTimeout(() => this.setState({ updated: true }), 5000);

      // Force update of the graph every 15 minutes
      setInterval(() => {
        this.setState({ updated: true });
      }, 60 * 1000 * 15);
    }
  };

  renderShape = () => {
    const ctx = this._graphContainer.getContext('2d');

    ctx.beginPath();
    ctx.moveTo(this._points[0].x, this._points[0].y);
    this._points.forEach((point, index, arr) => {
      if (index + 1 < arr.length) {
        const c = (point.x + arr[index + 1].x) / 2;
        const d = (point.y + arr[index + 1].y) / 2;
        ctx.quadraticCurveTo(point.x, point.y, c, d);
      }
    });
    ctx.lineTo(
      this.state.canvasWidth + this.props.range.x,
      this.state.canvasHeight,
    );
    ctx.lineTo(-this.props.range.x, this.state.canvasHeight);
    ctx.closePath();
    ctx.fillStyle = this.props.color;
    ctx.fill();
  };

  render() {
    return (
      <div
        className="fixed absolute-ns absolute--fill overflow-hidden"
        style={{ zIndex: -1 }}>
        <canvas
          width={
            this._graphContainer
              ? this._graphContainer.getClientRects()[0].width
              : 300
          }
          height={
            this._graphContainer
              ? this._graphContainer.getClientRects()[0].height
              : 300
          }
          className="w-100 h-100"
          ref={el => {
            this._graphContainer = el;
          }}
        />
      </div>
    );
  }
}

BackgroundGraph.defaultProps = {
  shouldAnimate: true,
  count: 8,
  range: {
    x: 5,
    y: 40,
  },
  duration: {
    min: 150,
    max: 400,
  },
  level: 0.02,
};

BackgroundGraph.propTypes = {
  color: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  count: PropTypes.number,
  shouldAnimate: PropTypes.bool,
  range: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  duration: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }),
  level: PropTypes.number,
};
export default BackgroundGraph;
