import * as Sentry from '@sentry/browser';

if (window.dashboard.sentryEnabled) {
  Sentry.init({
    dsn: 'https://7eb22c8c4dee4676aa834c95c4b40cce@sentry.io/1303665',
  });
}

export function captureMessage(message, args) {
  if (window.dashboard.sentryEnabled) {
    Sentry.captureMessage(message, args);
  } else {
    console.log('%s %O', message, args);
  }
}
