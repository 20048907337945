/* eslint-disable react/forbid-prop-types */
import React, { Component, Fragment } from 'react';
import { FormattedMessage as T, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import m from './messages';
import { formatValue } from './formatValue';
import { captureMessage } from './errors/sentry';
import { getTheme } from './utils';
import { nextView, updateMonitoringDataV2, initialMount } from './actions';

import {
  STEP_INTERVAL,
  POLL_INTERVAL,
  TIMEOUT_LIMIT,
  IPHONE_7_BATTERY_CAPACITY,
  WASHING_MACHINE_USAGE,
  TESLA_EPA_RANGE_FACTOR,
} from './constants';

import DashboardHeader from './DashboardHeader';
import BackgroundGraph from './BackgroundGraph';
import MainSection from './MainSection';
import DashboardStats from './DashboardStats';

function getQueryParamValue(param) {
  const url = new window.URL(window.location.href);
  return url.searchParams.get(param);
}

class TvDashboard extends Component {
  state = {
    errorMsg: '',
    theme: getTheme(),
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const installationId = getQueryParamValue('installation');
    const theme = getQueryParamValue('theme');
    if (theme) {
      this.updateTheme(theme);
    }
    dispatch(initialMount());
    if (!installationId) {
      this.setState({
        errorMsg: 'Missing required "installation" parameter',
      });
      return;
    }
    dispatch(updateMonitoringDataV2(installationId));
    this.startPollingInterval(installationId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ dataReceived }) {
    const { dispatch } = this.props;
    if (dataReceived && !this._stepInterval) {
      dispatch(nextView());
      this._stepInterval = setInterval(() => {
        dispatch(nextView());
      }, STEP_INTERVAL);
    }
  }

  componentWillUnmount() {
    clearInterval(this._stepInterval);
    clearInterval(this._dataLoadInterval);
  }

  // let's poll every minute
  startPollingInterval = installationId => {
    const { intl } = this.props;
    this._dataLoadInterval = setInterval(() => {
      if (
        !this.props.monitoringSteps.length &&
        new Date() - new Date(this.props.timeStamp) > TIMEOUT_LIMIT
      ) {
        captureMessage('TV dashboard timeout', {
          level: 'error',
          extra: {
            props: this.props,
          },
        });
        this.setState({
          errorMsg: intl.formatMessage(m.fetchErrorMessage),
        });
      } else {
        this.props.dispatch(updateMonitoringDataV2(installationId));

        if (this.state.errorMsg.length) {
          this.setState({
            errorMsg: '',
          });
        }
      }
    }, POLL_INTERVAL);
  };

  updateTheme = theme => {
    this.setState({
      theme: getTheme(theme),
    });
  };

  getErrorMessage() {
    const { errorMsg } = this.state;
    const { fetchFailed } = this.props;
    if (errorMsg) {
      return errorMsg;
    }

    if (fetchFailed) {
      return <T {...m.fetchErrorMessage} />;
    }
    return null;
  }

  render() {
    const { theme } = this.state;
    const {
      power,
      maxPower,
      currentEnergyDisplay,
      dataReceived,
      intl,
    } = this.props;
    const displayedErrorMessage = this.getErrorMessage();
    if (!dataReceived && !displayedErrorMessage) {
      return null;
    }
    const percent = Math.min((power[1] / (maxPower || 1)) * 100, maxPower);

    return (
      <div className="vh-100 w-100 flex flex-column">
        <DashboardHeader theme={theme} />
        <section className="flex flex-column relative flex-auto">
          <BackgroundGraph percent={percent} color={theme.primary} />
          {displayedErrorMessage ? (
            <div className="mw8 center flex justify-center items-center tc">
              <h1>{displayedErrorMessage}</h1>
            </div>
          ) : (
            <Fragment>
              <MainSection
                className="w-100 pa3 mt5 mt0-ns flex-auto justify-center items-center flex"
                theme={theme}
              />
              <div className="flex items-center-ns" style={{ flexGrow: 2 }}>
                <div className="w-100 flex-ns justify-between ph4 z-999">
                  <Card theming={theme}>
                    <DashboardStats
                      className="w-100"
                      topText={intl.formatMessage(m.phoneHeader)}
                      largeNumber={formatValue(
                        currentEnergyDisplay.data / IPHONE_7_BATTERY_CAPACITY,
                        false,
                      )}
                      bottomText={intl.formatMessage(m.phoneLabel)}
                    />
                  </Card>
                  <Card theming={theme}>
                    <DashboardStats
                      className="w-100"
                      topText={intl.formatMessage(m.washingMachineRoundsHeader)}
                      largeNumber={formatValue(
                        currentEnergyDisplay.data / WASHING_MACHINE_USAGE,
                        false,
                      )}
                      bottomText={intl.formatMessage(
                        m.washingMachineRoundsLabel,
                      )}
                    />
                  </Card>
                  <Card theming={theme}>
                    <DashboardStats
                      className="w-100"
                      topText={intl.formatMessage(m.teslaDistanceHeader)}
                      largeNumber={formatValue(
                        currentEnergyDisplay.data * TESLA_EPA_RANGE_FACTOR,
                        false,
                      )}
                      bottomText={intl.formatMessage(m.teslaDistanceLabel)}
                    />
                  </Card>
                </div>
              </div>
            </Fragment>
          )}
          {!!theme.secondaryLogo && (
            <div
              className="absolute bottom-0 right-0 dib pv2 ph2 br2 z-999"
              style={{ backgroundColor: 'rgba(255,255,255,.5)' }}>
              <T {...m.suppliedBy} />{' '}
              <theme.secondaryLogo className="h1 v-top" />
            </div>
          )}
        </section>
      </div>
    );
  }
}

const Card = ({ theming, ...rest }) => (
  <div className="h-100-ns w-100 w-33-ns dib ph2 v-mid mv4 mv0-ns">
    <div
      style={{ fontFamily: theming.font, color: theming.fontColor }}
      className="h-100-ns w-100 o-shadow tc flex items-center justify-center dashboard-card pa3 pv6-ns"
      {...rest}
    />
  </div>
);

Card.propTypes = {
  theming: PropTypes.object.isRequired,
};

TvDashboard.propTypes = {
  currentEnergyDisplay: PropTypes.object.isRequired,
  fetchFailed: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  power: PropTypes.array.isRequired,
  maxPower: PropTypes.number.isRequired,
  dataReceived: PropTypes.bool.isRequired,
  monitoringSteps: PropTypes.array.isRequired,
  timeStamp: PropTypes.number.isRequired,
};

const mapStateToProps = ({ tvdashboardState }) => ({
  currentEnergyDisplay: tvdashboardState.currentEnergyDisplay,
  fetchFailed: tvdashboardState.fetchFailed,
  power: tvdashboardState.power,
  maxPower: tvdashboardState.maxPower,
  dataReceived: tvdashboardState.dataReceived,
  monitoringSteps: tvdashboardState.monitoringSteps,
  timeStamp: tvdashboardState.timeStamp,
});

export default connect(mapStateToProps)(injectIntl(TvDashboard));
