// @flow
import React from 'react';
import { connect } from 'react-redux';

import TimerBar from './TimerBar';
import StepComponent from './StepComponent';
import type { Theme } from './type';

type Props = {
  currentStep: number,
  monitoringSteps: Array<number>,
  theme: Theme,
};

const CurrentStepBox = ({ currentStep, monitoringSteps, theme }: Props) =>
  monitoringSteps.length > 1 && (
    <div
      className="w-100 w5-l relative"
      style={{ backgroundColor: theme.primary }}>
      <div className="bg flex items-center justify-around ph2 h3-l h2">
        {[...new Array(monitoringSteps.length)].map((_, index) => (
          <StepComponent
            isActive={currentStep === index}
            background={theme.primary}
            color="o-white-primary"
            key={`StepComponent-${monitoringSteps[index]}`}
          />
        ))}
        <TimerBar color={theme.secondary} />
      </div>
    </div>
  );

const mapStateToProps = ({ tvdashboardState }, ownProps): Props => ({
  currentStep: tvdashboardState.currentStep,
  monitoringSteps: tvdashboardState.monitoringSteps,
  theme: ownProps.theme,
});

export default connect(mapStateToProps)(CurrentStepBox);
