import { addLocaleData } from 'react-intl';

// todo: load only necessary locale
import enLocaleData from 'react-intl/locale-data/en';
import nbLocaleData from 'react-intl/locale-data/nb';
import svLocaleData from 'react-intl/locale-data/sv';

addLocaleData(enLocaleData);
addLocaleData(nbLocaleData);
addLocaleData(svLocaleData);
